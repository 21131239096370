class AgeGateGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('AgeGateDefault before load');
	}

	afterLoad() {
		// console.log('AgeGateDefault After load');
		this.$el = $(this);

		// Bind Modal Events
		Modal.bindModalEvents(this.$el);

		const setMessage = (ageGateValue) => {
			// Turn on error message if ageGateValue is false
			if (ageGateValue === 'false') {
				this.$el
					.find('.bat-agegate-gloit--default-content-greeting')
					.removeClass('active');
				this.$el
					.find('.bat-agegate-gloit--default-content-greeting')
					.addClass('in-active');
				this.$el
					.find('.bat-agegate-gloit--default-content-error-message')
					.removeClass('in-active');
				this.$el
					.find('.bat-agegate-gloit--default-content-error-message')
					.addClass('active');
			} else {
				this.$el
					.find('.bat-agegate-gloit--default-content-greeting')
					.removeClass('in-active');
				this.$el
					.find('.bat-agegate-gloit--default-content-greeting')
					.addClass('active');
				this.$el
					.find('.bat-agegate-gloit--default-content-error-message')
					.removeClass('active');
				this.$el
					.find('.bat-agegate-gloit--default-content-error-message')
					.addClass('in-active');
			}
		};

		const ageGateInit = () => {
			const ageGateValue = Utils.getCookie('agegate');

			// Remove Close Button
			this.$el.find('.bat-modal-close').remove();

			// check ageGateValue
			if (!ageGateValue || ageGateValue === 'false') {
				$(`#${this.data.anchor}`)
					.addClass('active')
					.trigger('modal:open');
				setMessage(ageGateValue);
			}
		};

		const bindEvents = () => {
			// Bind Button Events
			$('.bat-agegate-gloit button[data-cta-type="true"').on('click', () => {
				Utils.setCookie('agegate', 'true');
				$(`#${this.data.anchor}`)
					.removeClass('active')
					.trigger('modal:close');
			});

			$('.bat-agegate-gloit button[data-cta-type="false"').on('click', () => {
				Utils.setCookie('agegate', 'false');
				setMessage('false');
				setTimeout(() => {
					
					Utils.eraseCookie('agegate');
					ageGateInit();
					window.location.href=this.data.ctaItems.filter(item => item.ctaType == "false")[0].cta.url;
					
				}, 5000);
				
			});
		};

		// Bind Button Events
		bindEvents();

		// Age Gate init
		ageGateInit();
	}


	renderComponent(data) {
		this.setAttribute('data-model', JSON.stringify(data));
	}

	beforeUpdate() {
		// console.log('AgeGateDefault before update');
	}

	afterUpdate() {
		// console.log('AgeGateDefault after update');
	}

	unload() {
		// console.log('AgeGateDefault after unload');
	}
}

!customElements.get('bat-agegate-gloit') &&
	customElements.define('bat-agegate-gloit', AgeGateGloIT);
